import React, { createRef, Component } from "react"

import Transition from '../components/transition.js'
import { Link } from "gatsby"
//import EmailForm from "../components/emailform.js"



class TheLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showBurger: false,
      bodyRef: createRef(),
      navRef: createRef(),
      position: 0,
      bodyOffset: 0,

    };
    this.handleBurgerClick = this.handleBurgerClick.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
  }
  handleBurgerClick() {
    this.setState(prevState => ({
      showModal: !prevState.showModal,

    }));
  }


  handleNavClick() {
    console.log("ugh")
    setTimeout(function () { //Start the timer
      this.setState({ showModal: false }) //After 1 second, set render to true
    }.bind(this), 200)
  }

  componentDidMount(prevProps, prevState) {

    this.updateOffsets()
    window.addEventListener('scroll', this.listenToScroll)
    window.addEventListener('resize', this.updateOffsets)
  }



  updateOffsets = () => {
    let bodyOffset = document.getElementById("content").offsetTop;
    this.setState(prevState => ({
      bodyOffset: bodyOffset
    }));
  }




  listenToScroll = () => {
    const scrolledPixels =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolledPercent = scrolledPixels / height * 100

    this.setState(prevState => ({
      position: {
        scrolledPercent: scrolledPercent,
        scrolledPixels: scrolledPixels,
      },
      showBurger: (scrolledPixels > this.state.bodyOffset)
    }))
  }


  render() {



    return (
      // wrapper div of component

      <div>
        <button id="logo" onClick={this.handleBurgerClick}>
          <span className={"logowrapper " + (this.state.showBurger ? 'nav ' : '') + (this.state.showModal ? 'modal  ' : '')} >
            <span className="logo">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="dot"></span>
            </span>
          </span>
        </button>

        <h1 id="brand">
          Gregory R. Miller &amp; Co.
        </h1>



        <div className="stripe">&nbsp;</div>


        <nav id="nav" className={(this.state.showModal ? 'open' : '')}>
          <ul>
            <li><Link to="/" partiallyActive={true} onClick={this.handleBurgerClick}>Home</Link></li>
            <li><Link partiallyActive={true} to="/titles" onClick={this.handleNavClick}>Books</Link></li>
            <li><Link partiallyActive={true} to="/artists" onClick={this.handleNavClick}>Artists</Link></li>
            <li><Link partiallyActive={true} to="/news" onClick={this.handleNavClick}>News</Link></li>
            <li><Link partiallyActive={true} to="/about" onClick={this.handleNavClick}>About</Link></li>
          </ul>
        </nav>

        <div id="pagewrap" ref={this.state.bodyRef}>
          <Transition location={this.props.location}>
            <div id="content" >
              {this.props.children}</div>
          </Transition>
        </div>


        <div id="footer">
          <Link to="/about">Gregory R. Miller &amp; Co.</Link>
        </div>
      </div>

    );
  }
}
// export the class so you can call it elsewhere
export default TheLayout;
