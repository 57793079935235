// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-titlepage-js": () => import("/opt/build/repo/src/templates/titlepage.js" /* webpackChunkName: "component---src-templates-titlepage-js" */),
  "component---src-templates-personpage-js": () => import("/opt/build/repo/src/templates/personpage.js" /* webpackChunkName: "component---src-templates-personpage-js" */),
  "component---src-templates-newspage-js": () => import("/opt/build/repo/src/templates/newspage.js" /* webpackChunkName: "component---src-templates-newspage-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-artists-js": () => import("/opt/build/repo/src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-titles-js": () => import("/opt/build/repo/src/pages/titles.js" /* webpackChunkName: "component---src-pages-titles-js" */)
}

